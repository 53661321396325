/*import React, { useEffect } from 'react'
import '../../App.css'
import CookieConsent, { Cookies, getCookieConsentValue, resetCookieConsentValue } from "react-cookie-consent";

function GVCookieBar(props) {

  return (<CookieConsent
    enableDeclineButton
    declineButtonText="Aceitar cookies"
    location="bottom"
    buttonText="Aceitar cookies"
    cookieName="gov-br-cookie-atendimento-speedmais"
    style={{ background: "#2B373B" }}
    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
    expires={150}
  >
    Para entender melhor aqueles que procuram o formulário de atendimento e melhorar a sua experiência de navegação, utilizamos cookies. Para escolher quais quer autorizar, clique em "Definir cookies".{" "}
  </CookieConsent>)
}

export default GVCookieBar;*/

import React, { useEffect, useState } from 'react'
import Stack from '@mui/material/Stack';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import GVButton from '../button';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import IconButton, { iconButtonClasses } from '@mui/material/IconButton';
import { styled } from '@mui/styles';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Link, List, listClasses, ListItemButton, ListItemText, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LaunchIcon from '@mui/icons-material/Launch';
import GVCard from '../card';
import '../../../App.css'
import './styles.css'
import { faCookieBite } from '@fortawesome/free-solid-svg-icons'
import { ThemeContext } from '../../../context/theme-context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Mexx2DOConsole } from '../../../utils/mexxTalkConsole';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GVBRCookiesBanner = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.container}`]: {
    width: '100%',
    display: 'block',
    [`& .${dialogClasses.paper}`]: {
      width: '100%',
      minWidth: '100%',
      margin: 0
    }
  },
}))

const GVBRIconButton = styled(IconButton)(({ theme }) => ({
  marginTop: 'var(--spacing-scale-base)',
  opacity: 1,
  color: 'var(--interactive)',
  cursor: 'pointer',
  display: 'inline-flex',
  fontSize: 'var(--font-size-scale-up-01)',
  fontWeight: '900',
  fontWeight: 'var(--font-weight-semi-bold)',
  height: 'var(--button-size)',
  justifyContent: 'center',
  overflow: 'hidden',
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: 0,
  textAlign: 'center',
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',
}))

export default function CookiesBanner() {
  const [bannerOpen, setBannerOpen] = React.useState(true); // true
  const [fullScreenOpen, setFullScreenOpen] = React.useState(false); // false
  const [open, setOpen] = React.useState(true); // true
  const { theme: themeColor } = React.useContext(ThemeContext);


  const closeBanner = () => {
    setBannerOpen(false);
    setOpen(false);
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleClickFullScreenOpen = () => {
    setFullScreenOpen(true);
    setOpen(false);
    listCookies();
    
  
  };

  const listCookies = () =>  {
    var theCookies = document.cookie.split(';');
    Mexx2DOConsole.log("theCookies")
    Mexx2DOConsole.log(theCookies)
    var aString = '';
    for (var i = 1 ; i <= theCookies.length; i++) {
        aString += i + ' ' + theCookies[i-1] + "\n";
    }
    return aString;
}

  const handleFullScreenClose = () => {
    setOpen(true);
    setFullScreenOpen(false);
  };

  const handleAcceptCookies = () => {
    setBannerOpen(false);
    setFullScreenOpen(false);
    setOpen(false);
  }


  // useEffect(() => {
  //   const handlePageLoad = () => {
  //     // Acessando todos os cookies após o carregamento da página
  //     const allCookies = Cookies.get();
  //     console.log('Todos os cookies:', allCookies);

  //     // Acessando um cookie específico
  //     const specificCookie = Cookies.get('cookieName');
  //     console.log('Cookie específico:', specificCookie);
  //   };

  //   if (document.readyState === 'complete') {
  //     handlePageLoad(); // Se a página já estiver carregada, chama imediatamente
  //   } else {
  //     window.addEventListener('load', handlePageLoad); // Caso contrário, espera o evento load
  //   }

  //   // Cleanup function para remover o listener após o efeito ser executado
  //   return () => {
  //     window.removeEventListener('load', handlePageLoad);
  //   };
  // }, []);

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={fullScreenOpen}
        TransitionComponent={Transition}
      >
        <Grid container spacing={2} sx={{ paddingLeft: '32px', paddingRight: '32px', paddingTop: 'var(--spacing-scale-3x)' }}>
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <h1 className='cookie-fs-modal-title' style={{
              fontSize: '41.804px',
              fontSize: 'var(--font-size-scale-up-06)',
              fontWeight: '300',
              fontWeight: 'var(--font-weight-light)',
              marginBottom: '32px',
              marginBottom: 'var(--spacing-scale-4x)',
              marginTop: 0
            }}>Respeitamos a sua Privacidade</h1>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{
            display: 'flex',
            alignContent: 'flex-start',
            justifyContent: 'flex-end',
            alignItems: 'flex-start'
          }}>
            <GVBRIconButton
              className='cookie-fs-modal-close'
              aria-label="close"
              onClick={handleFullScreenClose}>
              <CloseIcon />
            </GVBRIconButton>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: '0' }}>
            <p
              className='cookie-fs-modal-last-update'
              style={{
                fontSize: 'var(--font-size-scale-base)',
                fontWeight: 600,
                fontWeight: 'var(--font-weight-semi-bold)'
              }} >Última atualização: 01/02/2021</p>
            <p className='cookie-fs-modal-info'>Para entender melhor aquelkes que procuram o formulário de atendimento e melhorar sua experiência de navegação, utilizamos cookies. Para escolher quais quer autorizar, clique em "Definir cookies".</p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: '0' }}>
            <p
              className='cookie-fs-modal-cookie-classes'
              style={{
                fontSize: 'var(--font-size-scale-up-02)',
                fontWeight: 600,
                fontWeight: 'var(--font-weight-bold)'
              }} >Classes de cookies</p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: '0' }}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <span className='cookie-fs-modal-group-name' style={{
              color: 'var(--color-primary-default)',
              cursor: 'pointer'
            }} >Experiência do site (1)</span>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: '16px' }}>
            <GVCard>
              <Grid container spacing={0}>
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Grid container spacing={0}>
                    <Grid item xs={6} sm={6} md={3} lg={2} xl={2} style={{ paddingTop: '0' }}>
                      <p
                        className='cookie-fs-modal-cookie-site-experience-title cookie-fs-modal-cookie-paragraph-no-margin'>Cookies</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={9} lg={10} xl={10} className='cookie-fs-modal-cookie-site-experience-value' style={{ paddingTop: '0' }} >
                      <p className='cookie-fs-modal-cookie-paragraph-no-margin'>nome</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={2} xl={2} style={{ paddingTop: '0' }} >
                      <p
                        className='cookie-fs-modal-cookie-site-experience-title cookie-fs-modal-cookie-paragraph-no-margin'>Vencimento</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={9} lg={10} xl={10} className='cookie-fs-modal-cookie-site-experience-value' style={{ paddingTop: '0' }} >
                      <p className='cookie-fs-modal-cookie-paragraph-no-margin'>1 dia</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={2} xl={2} style={{ paddingTop: '0' }} >
                      <p
                        className='cookie-fs-modal-cookie-site-experience-title cookie-fs-modal-cookie-paragraph-no-margin'>Domínio</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={9} lg={10} xl={10} className='cookie-fs-modal-cookie-site-experience-value' style={{ paddingTop: '0' }} >
                      <p className='cookie-fs-modal-cookie-paragraph-no-margin'>speedmais.com</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={2} xl={2} style={{ paddingTop: '0' }} >
                      <p
                        className='cookie-fs-modal-cookie-site-experience-title cookie-fs-modal-cookie-paragraph-no-margin'>Empresa</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={9} lg={10} xl={10} className='cookie-fs-modal-cookie-site-experience-value' style={{ paddingTop: '0' }} >
                      <p className='cookie-fs-modal-cookie-paragraph-no-margin'>Speedmais</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={2} xl={2} style={{ paddingTop: '0' }} >
                      <p
                        className='cookie-fs-modal-cookie-site-experience-title cookie-fs-modal-cookie-paragraph-no-margin'>Finalidade</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={9} lg={10} xl={10} className='cookie-fs-modal-cookie-site-experience-value' style={{ paddingTop: '0' }} >
                      <p className='cookie-fs-modal-cookie-paragraph-no-margin'>Análise de métricas</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={2} xl={2} style={{ paddingTop: '0' }} >
                      <p
                        className='cookie-fs-modal-cookie-site-experience-title cookie-fs-modal-cookie-paragraph-no-margin'>Descrição</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={9} lg={10} xl={10} className='cookie-fs-modal-cookie-site-experience-value' style={{ paddingTop: '0' }} >
                      <p className='cookie-fs-modal-cookie-paragraph-no-margin'>Descrição</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>

                </Grid>
              </Grid>
            </GVCard>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: '0' }}>
            <Accordion
              disableGutters
              elevation={0} >
              <AccordionSummary
                sx={{ padding: '0!important' }}
                expandIcon={<ExpandMoreIcon sx={{ color: 'var(--interactive)', fill: 'var(--interactive)' }} />}
                aria-controls="cookie-fs-modal-notice"
                id="cookie-fs-modal-notice-header"
              >
                <Typography className='cookie-fs-modal-group-name' sx={{
                  color: 'var(--color-primary-default)',
                  cursor: 'pointer'
                }}>Aviso sobre cookies</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: '16px', display: 'flex', justifyContent: 'center' }}>
                  <Typography className='cookie-fs-modal-group-name' sx={{
                    color: 'var(--color-primary-default)',
                    cursor: 'pointer',
                    fontWeight:"bold"
                  }} onClick={() => { window.open('https://cadastro.acesso.gov.br/termo-de-uso') }}>Termo de uso, Aviso de Privacidade e Declaração de Cookies <LaunchIcon sx={{ color: 'var(--color-primary-default)', fill: 'var(--color-primary-default)' }} /></Typography>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Divider />

          </Grid>
        </Grid>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          gap={2}
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            m: 0,
            p: 2,
            borderWidth: 0,
            borderTopWidth: 1,
            paddingRight: '32px',
            paddingBottom: '32px',
          }}>
          <Box
            sx={{
              flexShrink: 1,
              alignSelf: { xs: 'flex-start', sm: 'center' },
            }} ></Box>
          <Stack
            gap={2}
            direction={{
              xs: 'row-reverse',
              sm: 'row',
            }}
            sx={{
              flexShrink: 0,
              alignSelf: { xs: 'flex-end', sm: 'center' },
            }} >
            <GVButton size="small" onClick={handleAcceptCookies} variant="contained" label="Aceitar" />
          </Stack>
        </Stack>
      </Dialog>
      <GVBRCookiesBanner
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Fade appear={false} in={bannerOpen}>
          <Paper
            role="dialog"
            aria-modal="false"
            aria-label="Cookie banner"
            square
            tabIndex={-1}
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              m: 0,
              p: 2,
              borderWidth: 0,
              borderTopWidth: 1,
              paddingRight: '32px',
              paddingBottom: '32px',
            }}
          >
            <Container component="main" sx={{ pt: 3 }}>
              <Typography paragraph>
                Para entender melhor aqueles que procuram o formulário de atendimento e melhorar a sua experiência de navegação, utilizamos cookies.<span style={{ fontWeight: 'bold' }}> Ao aceitar, você terá acesso a todas as funcionalidades do site. Se clicar em "Rejeitar Cookies", os cookies que não forem estritamente necessários serão desativados.</span>Para escolher quais quer autorizar clique em "Gerenciar cookies"
              </Typography>
            </Container>
            <Stack
            
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent={{ xs: 'center', sm: 'flex-end' }}
              alignItems={{ xs: 'center', sm: 'center' }}
              spacing={2}
              sx={{ width: '100%' }}
            >
              {/* <Box
                sx={{
                  flexShrink: 1,
                  alignSelf: { xs: 'flex-start', sm: 'center' },
                }}
              ></Box> */}
              
           
                <GVButton sx={{ width: { xs: '100%', sm: 'auto' } }} startIcon={<FontAwesomeIcon icon={faCookieBite} sx={{ mr: 0.5 }} fontSize="inherit" style={themeColor === 'light' ? { color: 'var(--interactive)', width: '13.33px' } : { color: 'var(--interactive-dark)', width: '13.33px' }} />} size="small" onClick={handleClickFullScreenOpen} label="Gerenciar cookies" />
                <GVButton sx={{ width: { xs: '100%', sm: 'auto' } }} size="small" variant="outlined" label="Rejeitar cookies" />
                <GVButton sx={{ width: { xs: '100%', sm: 'auto'} }} size="small" onClick={handleAcceptCookies} variant="contained" label="Aceitar cookies" />
            
            </Stack>
          </Paper>
        </Fade>
      </GVBRCookiesBanner>
    </React.Fragment>
  );
}