import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'

export default function GVMyDataDialogContent() {
  return (<Grid container spacing={2}>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Typography variant="body1" component="span" >A tabela abaixo resume as informações sobre o tratamento dos seus dados pessoais:</Typography>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Table aria-label="confirm-data-declaration-table">
        <caption>A tabela abaixo resume as informações sobre o tratamento dos seus dados pessoais:</caption>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body1" component="span" fontWeight="bold">Dado pessoal</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1" component="span" fontWeight="bold">Finalidade</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1" component="span" fontWeight="bold">Descrição do tratamento</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1" component="span" fontWeight="bold">Tempo de retenção</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
                <Typography variant="body1" component="span">Número do CPF</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" component="span">Identificar por biografia, comprovar identidade</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" component="span">Identificação do titular, comparação com documento</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" component="span">Retidos para auditoria</Typography>
              </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
                <Typography variant="body1" component="span">Nome</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" component="span">Comprovar identidade</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" component="span">Comparação com o documento</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" component="span">Retidos para auditoria</Typography>
              </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
                <Typography variant="body1" component="span">E-mail</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" component="span">Notificar</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" component="span">Cadastro do e-mail na conta e notificação do resultado</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" component="span">Retidos para auditoria</Typography>
              </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
                <Typography variant="body1" component="span">Leitura do rosto</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" component="span">Comprovar identidade</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" component="span">Comparação com a leitura do documento</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" component="span">Retidos para auditoria</Typography>
              </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
                <Typography variant="body1" component="span">Leitura do documento</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" component="span">Comprovar identidade</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" component="span">Comparação com a leitura do rosto e com o CPF e o nome</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" component="span">Retidos para auditoria</Typography>
              </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Typography variant="body1" component="span" >Para solicitar informações sobre o tratamento de seus dados, registre uma solicitação em nosso canal de atendimento (<a href='https://e.gov.br/solicitaratendimentogovbr' target='_blank'>e.gov.br/solicitaratendimentogovbr</a>)</Typography>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Typography variant="body1" component="span" >Todas as informações referentes ao tratamento dos seus dados pessoais se encontram em nosso <a href="https://cadastro.acesso.gov.br/termo-de-uso" target='_blank'>Termo de Uso e Aviso de Privacidade</a>.</Typography>
    </Grid>
  </Grid>)
}