import React, { useEffect } from 'react'
import { Grid, Typography } from '@mui/material';
import * as componentActions from '../../../../redux/actions/alter-email-step-for-mine'
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import PortraitIcon from '@mui/icons-material/Portrait';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import GVMessage from '../../../../components/gov-br/message';
import { useFormContext } from 'react-hook-form';
import GVFeedback from '../../../../components/feedback';

const StepFaceRecognition = React.memo(function StepFaceRecognition(props) {
  const completed = props.stepper.completed
  const isCompleted = completed?.[3] === true;

  const [legalResponsible, setLegalResponsible] = React.useState(false)
  const [legalRepresentant, setLegalRepresentant] = React.useState(false)

  const stepsData = useSelector((state) => state.calledServiceSettingsStep.steps)
  const stepForMinor = stepsData && stepsData.length > 0 ? stepsData.filter(element => element.step === 3).sort((a, b) => a.order - b.order) : []
  const stepForOther = stepsData && stepsData.length > 0 ? stepsData.filter(element => element.step === 4).sort((a, b) => a.order - b.order) : []

  const methods = useFormContext()
  const formValues = methods.watch()

  useEffect(() => {
    let issetLegalReponsible = stepForMinor.find(element => element.calledservicesettingsorganizationfield?.label === "Nome do responsável legal")
    if (issetLegalReponsible) {
      const fieldId = issetLegalReponsible.calledservicesettingsorganizationfield?.id
      if (formValues[`ofield-${fieldId}`] && formValues[`ofield-${fieldId}`].length > 0)
        setLegalResponsible(true)
    }
    let issetOtherLegalReponsible = stepForOther.find(element => element.calledservicesettingsorganizationfield?.label === "Nome do representante legal")
    if (issetOtherLegalReponsible) {
      const fieldId = issetOtherLegalReponsible.calledservicesettingsorganizationfield?.id
      if (formValues[`ofield-${fieldId}`] && formValues[`ofield-${fieldId}`].length > 0)
        setLegalRepresentant(true)
    }
  }, [])

  return (<React.Fragment>
    <Grid container spacing={2}>
      {isCompleted && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <GVMessage success>Esta etapa já foi concluída. Caso deseje alterar os dados, volte ao início</GVMessage>
      </Grid>}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <PortraitIcon style={{ fontSize: 'var(--font-size-scale-up-02)' }} />
        <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '5px', fontSize: 'var(--font-size-scale-up-02)' }} >Leitura do rosto do {(legalResponsible) ? "responsável legal" : legalRepresentant ? "representante legal" : "titular"}</Typography>
      </Grid>
       <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <GVFeedback warning2>Aviso de fotossensibilidade. Esta verificação contém sequências de luzes que podem afetar pessoas fotossensíveis</GVFeedback>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body1" component="span" >A seguir, será necessário realizar a leitura do rosto do {(legalResponsible) ? "responsável legal" : legalRepresentant ? "representante legal" : "titular"} usando a</Typography>
        <Typography variant="body1" component="span" fontWeight="bold" > câmera do seu dispositivo</Typography>
        <Typography variant="body1" component="span" >.</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body1" component="span" >Esta etapa é importante para evitar fraudes e garantir a segurança da sua conta gov.br.</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body1" component="span" >Será possível usar a câmera frontal ou webcam do seu dispositivo ou, se estiver disponível, a câmera traseira. Por isso, o processo é mais fácil de ser realizado em um celular. Caso tenha dificuldade, você pode pedir o auxílio de outra pessoa de confiança.</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <VisibilityOffIcon style={{ fontSize: 'var(--font-size-scale-up-02)' }} />
        <Typography variant="body1" component="span" style={{ paddingLeft: '5px' }} >As orientações serão reproduzidas por som para aqueles cegos ou com visão reduzida</Typography>
      </Grid>
    </Grid>
  </React.Fragment>)
})

const mapStateToProps = state => ({
  stepper: state.stepper
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StepFaceRecognition);