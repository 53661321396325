import React from 'react'
import './styles.css'

const Footer = (props) => {
  
  return (<footer className="br-footer">
      <div className="container-lg">
        <div className="logo"><a href="https://www.gov.br/pt-br" className="footer-portal-logo" target='blank'><img src="https://www.gov.br/++theme++padrao_govbr/img/govbr.png" alt="Acesse o portal gov.br"/></a></div>
        <div className="d-none d-sm-block">
          <div className="row align-items-end justify-content-between py-5">
            <div className="col assigns text-right">
              <a style={{ font: 'normal bold 11px Open Sans, sans-serif', '&:focus-visible': {
                outline: 'rgb(194, 133, 12) dashed 4',
                outlineOffset: 4
              } }} className="logo-acesso-footer" href="https://www.gov.br/acessoainformacao/pt-br" title="Acesse o portal sobre o acesso à informação" target='blank'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 117 49" height="49" width="117" shapeRendering="geometricPrecision" textRendering="geometricPrecision"><defs><style type="text/css"></style></defs><circle cx="22" cy="23" r="22" fill="#FFF"></circle><path style={{ stroke:'#222', strokeWidth:9, strokeLinecap:'round' }} d="m 22,23 v 13"></path><path style={{ stroke:'#FFF', strokeWidth:4, strokeLinejoin: 'round' }} d="m 4,43 3,-6 4,3 z"></path><circle r="4.5" cy="11" cx="22" fill="#222"></circle><g fill="#FFF"><text x="47" y="22"><tspan className="a" y="18">Acesso à</tspan><tspan className="a" x="47" y="31">Informação</tspan></text></g></svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>)
}

export default Footer