import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import '../../../App.css'
import '../../../styles/spacing/spacing.css'
import '../../../styles/fonts/font-size.css'
import PropTypes from "prop-types"
import GVButton from '../button'
import GVStepperPannelContent from '../step-pannel-content'
import { useStyles, wizardStyles, GVBRStepper, GVBRStep, GVBRStepButton, GVBRStepConnector, GVBRStepIcon, GVBRStepLabel, StepperPannelFooter } from './component-styles'
import { useFormContext } from 'react-hook-form'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as componentActions from '../../../redux/actions/stepper'
import GvDialog from '../dialog'
import * as triggersComponentActions from '../../../redux/actions/triggers';
import GVFeedback from '../../feedback'
import { Mexx2DOConsole } from '../../../utils/mexxTalkConsole'
import theme from '../../../styles/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { ThemeContext } from '../../../context/theme-context'
import './after-styles.css'
import { QuestionMark } from '@mui/icons-material'
import GVMyDataDialogContent from '../dialog/my-data-dialog-content'

const GVStepper = forwardRef(({ handleBack, steps, CompletedStep, setCompletedStepper, setTotalSteps, resetStepper, completedRef, trigger, setTriggerResetSteps, resetTriggerResetSteps }) => {
  //const trigger = useSelector((state) => state.triggers.trigger)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { theme: themeColor } = React.useContext(ThemeContext);

  const methods = useFormContext()
  const { watch, reset } = methods;
  const formValues = watch()
  const wizardClasses = wizardStyles()
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [validationErrors, setValidationErrors] = React.useState({});
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [myDataialogOpen, setMyDataDialogOpen] = React.useState(false)

  const onOpenDialog = () => {
    setDialogOpen(true)
  }

  const onCloseDialog = () => {
    setDialogOpen(false)
  }

  const onOpenMyDataDialog = () => {
    Mexx2DOConsole.log("MyDataDialogOpn")
    setMyDataDialogOpen(true)
  }

  const onCloseMyDataDialog = () => {
    setMyDataDialogOpen(false)
  }

  const handleConfirm = (e) => {
    e.preventDefault()
    handleBackToCaptcha()
  }

  const filteredSteps = steps.filter(step => step.showCondition && step.showCondition(formValues));
  console.log("filteredSteps")
  console.log(filteredSteps)
  const totalSteps = () => {
    return filteredSteps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? filteredSteps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    Mexx2DOConsole.log("filteredSteps")
    Mexx2DOConsole.log(filteredSteps)
    
    Mexx2DOConsole.log("newActiveStep")
    Mexx2DOConsole.log(newActiveStep)
   
    
  };

  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = async () => {
    const currentStep = filteredSteps[activeStep];
    const { components } = currentStep;

    const stepValidationErrors = {};
    const actualComponent = components.find((item) => {
      if (item.condition && item.condition(formValues))
        return item
    })
    if (actualComponent.completeCondition != null) {
      let completeCondition = await actualComponent.completeCondition(formValues)
      if (!completeCondition)
        stepValidationErrors[activeStep] = true;
    }

    setValidationErrors(stepValidationErrors);

    if (Object.keys(stepValidationErrors).length === 0) {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      setCompletedStepper(activeStep);
      handleNext();
    }
  };

  const handleBackToStart = () => {
    setActiveStep(0);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleBackToCaptcha = () => {
    reset()
    handleBack()
    if (!trigger) {
      setTriggerResetSteps();
      setTimeout(() => resetTriggerResetSteps(), 500);
    }
    resetStepper()
  }

  useImperativeHandle(completedRef, () => ({
    handleComplete
  }))

  useEffect(() => {
    setTotalSteps(filteredSteps.length)
  }, [filteredSteps.length])

  return (<Box className={wizardClasses.root} sx={{ width: '100%' }}>
    <GVBRStepper className={classes.root} nonLinear activeStep={activeStep} alternativeLabel connector={<GVBRStepConnector />}>
      {filteredSteps.map((step, index) => (
        <GVBRStep key={step.label} completed={completed[index]}>
          <GVBRStepButton color="inherit" disabled={!(completed[index]) && ( (index != activeStep ) )} onClick={handleStep(index)} disableTouchRipple disableRipple>
            <GVBRStepLabel StepIconComponent={GVBRStepIcon} >
              {step.label}
            </GVBRStepLabel>
          </GVBRStepButton>
        </GVBRStep>
      ))}
    </GVBRStepper>
    <GVStepperPannelContent
      steps={filteredSteps}
      activeStep={activeStep} />
    <StepperPannelFooter>
      {allStepsCompleted() ? CompletedStep ? (
        <React.Fragment>
          <CompletedStep />
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pt: 2 }}>
            <GVButton onClick={onOpenDialog}
              label="Voltar ao início" />
            <GVButton
              variant="outlined"
              color="inherit"
              onClick={onOpenMyDataDialog}
              sx={{ mr: 1 }}
              label="Uso dos meus dados"
              startIcon={<QuestionMark />} />
          </Box>
        </React.Fragment>) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <GVButton onClick={onOpenDialog}
              label="Voltar ao início" />
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <GVButton onClick={onOpenDialog}
              label="Voltar ao início" />
            <Box sx={{ flex: '1 1 auto' }} />
            {activeStep > 0 && <GVButton
              variant="outlined"
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBackStep}
              sx={isMobile ? { "& .MuiButton-startIcon": { margin: "0px!important", mr: 1 } } : { mr: 1 }}
              style={isMobile ? { borderRadius: '50%', width: 0, minWidth: 0, padding: '0 21px', marginRight: 6 } : {}}
              label={isMobile ? '' : "Voltar"}
              startIcon={isMobile && <FontAwesomeIcon icon={faChevronLeft} sx={{ mr: 0.5 }} fontSize="inherit" style={themeColor === 'light' ? { color: 'var(--interactive)', width: '13.33px' } : { color: 'var(--interactive-dark)', width: '13.33px' }} />}
            />}
            <GVButton
              ref={completedRef}
              onClick={handleComplete}
              variant="contained"
              label={(completedSteps() === totalSteps() - 1 && activeStep == totalSteps() - 1) && (!isMobile)
                ? 'Enviar'
                : isMobile ? '' : 'Avançar'}
              style={isMobile ? { borderRadius: '50%', width: 0, minWidth: 0, padding: '0 21px' } : {}}
              sx={isMobile ? { "& .MuiButton-startIcon": { margin: "0px!important" } } : {}}
              startIcon={isMobile && <FontAwesomeIcon icon={faChevronRight} sx={{ mr: 0.5 }} fontSize="inherit" style={themeColor === 'light' ? { color: 'var(--background-light)', width: '13.33px' } : { color: 'var(--background-dark)', width: '13.33px' }} />}
            />
          </Box>
        </React.Fragment>
      )}
    </StepperPannelFooter>
    <GvDialog
      open={dialogOpen}
      onClose={onCloseDialog}
      title={<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <GVFeedback warning2>Atenção</GVFeedback>
      </Grid>}
      content={<Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="h6" textTransform="initial" component="span" >Voltar ao início</Typography>
        </Grid>
        {!allStepsCompleted() && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body1" component="span" >Ao voltar ao início você vai perder todo o progresso já realizado.</Typography>
        </Grid>}
        {!allStepsCompleted() && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body1" component="span" >Deseja continuar?</Typography>
        </Grid>}
        {allStepsCompleted() && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body1" component="span" >Deseja realizar um novo atendimento?</Typography>
        </Grid>}
      </Grid>}
      actions={
        <>
          <GVButton
            variant="outlined"
            color="inherit"
            onClick={onCloseDialog}
            sx={{ mr: 1 }}
            style={isMobile ? { width: '100%', marginLeft: 16, marginRight: 16, marginBottom: 8 } : {}}
            label="Cancelar" />
          <GVButton
            onClick={handleConfirm}
            variant="contained"
            sx={{ mr: 1 }}
            style={isMobile ? { width: '100%', marginLeft: 16, marginRight: 16 } : {}}
            label="Sim" />
        </>
      }
      fullWidth={true}
      maxWidth={'md'}
      name={'reset-stepper-form-confirmation'} />
    <GvDialog
      open={myDataialogOpen}
      onClose={onCloseMyDataDialog}
      title={"Uso dos meus dados"}
      content={<GVMyDataDialogContent />}
      actions={
        <GVButton
          onClick={onCloseMyDataDialog}
          variant="contained"
          label="Voltar" />
      }
      fullWidth={true}
      maxWidth={'md'}
      name={'personal-data-email-confirmation'} />
  </Box>)
})

GVStepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      components: PropTypes.arrayOf(
        PropTypes.shape({
          component: PropTypes.elementType.isRequired,
          condition: PropTypes.func.isRequired,
          completeCondition: PropTypes.func.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  CompletedStep: PropTypes.elementType.isRequired,
  handleBack: PropTypes.func.isRequired,
  formSubmit: PropTypes.func,
  resetStepper: PropTypes.func
};

const mapStateToProps = state => ({
  stepper: state.stepper,
  trigger: state.triggers.trigger
})

const mapDispatchToProps =
  dispatch => bindActionCreators(Object.assign({}, componentActions, triggersComponentActions), dispatch)

// Função de comparação para memo (opcional)
const areEqual = (prevProps, nextProps) => {
  return prevProps.steps === nextProps.steps &&
    prevProps.CompletedStep === nextProps.CompletedStep &&
    prevProps.setCompletedStepper === nextProps.setCompletedStepper &&
    prevProps.setTotalSteps === nextProps.setTotalSteps &&
    prevProps.resetStepper === nextProps.resetStepper &&
    prevProps.completedRef === nextProps.completedRef &&
    prevProps.handleBack === nextProps.handleBack;
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(GVStepper, areEqual));