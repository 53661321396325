import React from 'react';
import IconButton from '@mui/material/IconButton';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import { useVwContext } from '../../context/vw-context';
import GVIconButton from '../icon-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignLanguage } from '@fortawesome/free-solid-svg-icons'

const VwButton = (props) => {
  const { toggleVwClass, isVwActive } = useVwContext();
  
  return (
    <GVIconButton onClick={toggleVwClass} disabled={isVwActive} aria-label="Acessibilidade">
      <FontAwesomeIcon icon={faSignLanguage} style={props.btnStyle && props.btnStyle} aria-label= "vLibras" alt ="vLibras" />
    </GVIconButton>
  );
};

export default VwButton;