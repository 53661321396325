import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'; 
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { Loader, ThemeProvider } from '@aws-amplify/ui-react';
import * as Auth from '@aws-amplify/auth';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../material-snackbars';
import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';
import './styles.css'
import { Button, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { cancelFaceLivenessSession, createFaceLivenessSession, getFaceLivenessSessionResult, restartFaceLivenessSession } from '../../services/mexx-2-face/faceliveness.service';
import { BlockedDocumentFaceLivenessError } from '../../services/blocked-document.service';
import { env } from '../../env';
import { Mexx2DOConsole } from '../../utils/mexxTalkConsole';
import ApiMexx2Face from '../../services/mexx-2-face/apiMexx2Face';
import CustomFaceLivenessDetector from '../custom-face-liveness-detector';
import IconSun from '../icons/icon-sun';
import IconNoGlasses from '../icons/icon-no-glasses';
import IconSunBright from '../icons/icon-sun-bright';
import IconFaceFrame from '../icons/icon-face-frame';
import * as faceRecognitionStepComponentActions from '../../redux/actions/face-recognition-step'
import IconMoveFace from '../icons/icon-move-face';

I18n.putVocabularies(translations);
I18n.setLanguage('pt');

const LivenessFaceClass = forwardRef(({ setFaceRecognitionStepError, changeButtonLabel ,getFaceLivenessSessionResults, handleRestartRef, addFaceRecognitionStepLimitAttemp, document, closeFaceRecognitionDialog,
	openBlockedDocumentFaceLivenessAttemptDialog }) => {
  const [createLivenessApiData, setCreateLivenessApiData] = useState('')
  const [loading, setLoading] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [notificationVariant, setNotificationVariant] = useState('error')
  const [notificationMessage, setNotificationMessage] = useState('')
  const [error, setError] = useState(false)

  Mexx2DOConsole.log("document here 1")
  Mexx2DOConsole.log(document)
	useEffect(() => {
    if (ApiMexx2Face == null)
      setFaceRecognitionStepError(true)

	Mexx2DOConsole.log("document here 2")
  Mexx2DOConsole.log(document)

    fetchCreateLiveness();
  }, [])

	useEffect(() => {
    if (error === true)
      setFaceRecognitionStepError(true)
  }, [error])

	const fetchCreateLiveness = async () => {
		setLoading(true)
		try {
			let response = await createFaceLivenessSession()
			if (response && response.success != null && response.success && response.data != null)
				await setCreateLivenessApiData(response.data)
			
			let username = env.REACT_APP_MEXX_2FACE_AWS_REKOGNITION_CREDENTIAL_USER_NAME;
			let password=  env.REACT_APP_MEXX_2FACE_AWS_REKOGNITION_CREDENTIAL_USER_PASSWORD; 
			let email = env.REACT_APP_MEXX_2FACE_AWS_REKOGNITION_CREDENTIAL_USER_EMAIL;

			Mexx2DOConsole.log("=== fetchCreateLiveness ===")
			Mexx2DOConsole.log(env)
			Mexx2DOConsole.log(username);
			Mexx2DOConsole.log(email);

				try {
						await Auth.signIn({
							username,
							password
						}).then((user) => {
							Mexx2DOConsole.log('Auth.signIn');
							Mexx2DOConsole.log(user);
							if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
							const { requiredAttributes } = user.challengeParam;
							Auth.confirmSignIn ({ password })
								.then((user) => {
								Mexx2DOConsole.log('PASSWORD ALTERADO SUECESSO!');
								})
								.catch((e) => {
								Mexx2DOConsole.log('error complete New Password:', e);
								});
							} else {
								Mexx2DOConsole.log('other situations');
							}
							Mexx2DOConsole.log('SUCESSO!');
							changeButtonLabel();
						})
						.catch((e) => {
							Mexx2DOConsole.log('error signing up:', e);
							Mexx2DOConsole.log(e)
						});
				} catch (error) {
					Mexx2DOConsole.log('error login up:', error);
				}
				
				setLoading(false)
				changeButtonLabel();
	
			} catch (error) {
				setLoading(false)
				setOpenNotification(true)
				setNotificationVariant('error')
				setNotificationMessage('error cancel session:'+JSON.stringify(error))
			}
  }

	const validateFaceLivenessSessionResultData = (data) => {
		if (data != null && data.confidence != null && data.isalive != null && data.base64image != null && data.imagetempurl != null){
			if(data.status == "SUCCEEDED"){
				if(data.confidence >= 85.0){
					return true
				}
			}
		}
		return false
	}

	const handleAnalysisComplete = async () => {

		Mexx2DOConsole.log("erro encontrado aqui 3");
		setLoading(true)
		try {
			let response = await getFaceLivenessSessionResult(createLivenessApiData)
			if (response && response.success != null && response.success && validateFaceLivenessSessionResultData(response.data)) {
				setLoading(false)
				getFaceLivenessSessionResults(response.data)
			} else {
				setLoading(false)
				setError(true)
				setOpenNotification(true)
				setNotificationVariant('error')
				setNotificationMessage("Não identificamos uma pessoa viva na verificação. Favor atentar para as observações e tentar novamente.")
				await handle2FaceError();
			
			}
		} catch (error) {
			setLoading(false)
			setError(true)
			setOpenNotification(true)
			setNotificationVariant('error')
			setNotificationMessage(JSON.stringify(error))
		}
  }

	const handleUserCancel = async () => {

		Mexx2DOConsole.log("erro encontrado aqui 2");
		
		try {
			await restartSession()
		} catch (error) {
			setLoading(false)
			setError(true)
			setOpenNotification(true)
			setNotificationVariant('error')
			setNotificationMessage('error cancel session:'+JSON.stringify(error))
		}
	
	}

	const handle2FaceError = async (e) => {
		
		try {
			setLoading(true)

			let data = {
				document : document
			}

			let response = await BlockedDocumentFaceLivenessError(data)
			if (response && response.success != null && response.success == true ) {

				Mexx2DOConsole.log("resposta 1 aqui")
				Mexx2DOConsole.log(response)
				setLoading(false)
				
			} else {

				Mexx2DOConsole.log("resposta 2 aqui")
				closeFaceRecognitionDialog();
				openBlockedDocumentFaceLivenessAttemptDialog(document);
				setLoading(false)
			
			}
		} catch (error) {
			setLoading(false)
		}
		
	}

	const handleExternalRestartSession = async () => {
		addFaceRecognitionStepLimitAttemp()

		await restartSession()
		
		// await restartSession()
	}

	const restartSession = async () => {
		try {
			setLoading(true)

			let response = await restartFaceLivenessSession(createLivenessApiData)
			if (response && response.success != null && response.success && response.data != null && response.data.sessionid != null && response.data.sessionid.length > 0) {
				setLoading(false)
				setError(false)
				setCreateLivenessApiData(response.data.sessionid)
			} else {
				setLoading(false)
			}
		} catch (error) {
			setLoading(false)
			setError(true)
			setOpenNotification(true)
			setNotificationVariant('error')
			setNotificationMessage(JSON.stringify(error))
		}
	}

	const handleError = async (error) => {
			Mexx2DOConsole.log(error);
			Mexx2DOConsole.log("erro encontrado aqui 1");
			try {

				setLoading(true)

				let response = await cancelFaceLivenessSession(createLivenessApiData)
				if (response && response.success != null && response.success && response.data != null) {
					setLoading(false)
					setOpenNotification(true)
					setNotificationVariant('error')
					setNotificationMessage("Ocorreu um erro no processo. Leia as orientações abaixo!")

					await handle2FaceError();
				} else {
					setLoading(false)
				}
				setError(true)

				
			} catch (error) {
				setLoading(false)
				setError(true)
				setOpenNotification(true)
				setNotificationVariant('error')
				setNotificationMessage(JSON.stringify(error))
			}
	
	};  
	const closeNotification = () => {
		setOpenNotification(false)
	}
	
	useImperativeHandle(handleRestartRef, () => ({
    handleExternalRestartSession
  }))

	return (
			<div style={{paddingLeft:0, marginLeft:0}}>		
				
				{loading ? (
					<Overlay>
						<CircularProgress color="mainsecondary" />
					</Overlay>				
				) : (
					<>
					{!error ?
					(<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<div className='liveness-face-detector'>
								<ThemeProvider>
									<CustomFaceLivenessDetector
										sessionId={createLivenessApiData}
										handleAnalysisComplete={handleAnalysisComplete}
										handleUserCancel={handleUserCancel}
										handleError={handleError} />
								</ThemeProvider>
							</div>
						</Grid>
					</Grid>)
					:
					(
									<Grid container spacing={2}>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<Typography variant="body1" component="span" >Siga as instruções abaixo para realizar a leitura do rosto:</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', alignItems: 'center' }}>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<IconSun />
											<Typography variant="body1" component="span" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Esteja em uma área bem iluminada que não esteja exposta à luz solar direta</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', alignItems: 'center' }}>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<IconNoGlasses />
											<Typography variant="body1" component="span" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Não cubra o rosto com óculos de sol, máscara ou demais acessórios</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', alignItems: 'center' }}>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<IconFaceFrame />
											<Typography variant="body1" component="span" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mantenha o rosto dentro da moldura oval durante todo o processo</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', alignItems: 'center', marginLeft:'5px' }}>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<IconMoveFace />
											<Typography variant="body1" component="span" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Apenas movimente o rosto se solicitado</Typography>
										</Grid>
									</Grid>)
					}
					</>
				)}
				
				<CustomizedSnackbars
          variant={notificationVariant}
          message={notificationMessage}
          isOpen={openNotification}
          toClose={closeNotification} />					
			</div>
		);
})

LivenessFaceClass.propTypes = {
	handleErrorFaceLivenessSession: PropTypes.func.isRequired,
	getFaceLivenessSessionResults: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ })

const mapDispatchToProps =
  dispatch => bindActionCreators(Object.assign({}, faceRecognitionStepComponentActions), dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(LivenessFaceClass);

