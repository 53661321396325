import React, { useContext, useEffect } from 'react'
import './styles.css'
import ContrastIcon from '@mui/icons-material/Contrast';
import GVIconButton from '../../icon-button';
import { ThemeContext } from '../../../context/theme-context';
import VwButton from '../../vw-button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';
import { env } from '../../../env';
import { useHistory, useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleHalfStroke, faGrip, faSignLanguage } from '@fortawesome/free-solid-svg-icons'
import { useVwContext } from '../../../context/vw-context';

const Header = (props) => {
  let history = useHistory();
  let { lang } = useParams();

  const { toggleVwClass, isVwActive } = useVwContext();
  const { toggleTheme, theme } = useContext(ThemeContext);

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const alterEmail = () => {
    handleClose()
    history.push(`/${lang}/alterar-email-v2`);
  }

  const desativarAutenticacao = () => {
    handleClose()
    history.push(`/${lang}/desativar-2fa-v2`);
  }

  useEffect(() => {
    toggleVwClass()
  }, [])

  return (<header className={"br-header compact" + (theme === 'dark' ? " dark-mode" : "")}>
      <div className="container-lg">
        <div className="header-top">
          <div className="header-logo">
            <a href="https://www.gov.br/pt-br" className="portal-logo" target='blank'>
              <img
                src="https://www.gov.br/++theme++padrao_govbr/img/govbr-logo-large.png" 
                alt="Acesse o portal gov.br"/>
            </a>
              <span className="br-divider vertical"></span>
            <div className="header-sign">
              Ministério da Gestão e da<br/>Inovação em Serviços Públicos
            </div>
          </div>
          <div className="header-actions">
            <div className="header-links dropdown">
              <div className="br-list">
                <div className="header">
                  <div className="title">Acesso Rápido</div>
                </div>
                <Link className="br-item" to={`/${lang}/alterar-email-v2`}>Alterar e-mail na conta gov.br</Link >
                <Link  className="br-item" to={`/${lang}/desativar-2fa-v2`}>Desativar verificação em duas etapas</Link >
              </div>
            </div>
            <div className="header-functions">
              <div className="br-list expand">
                <div className="header">
                  <div className="title">Funcionalidades do Sistema</div>
                </div>
                <div className="br-item">
                  <GVIconButton onClick={toggleTheme}  type="button" aria-label="Tema">
                    <FontAwesomeIcon aria-label= "Modo de alto contraste" alt="Modo de alto contraste" icon={faCircleHalfStroke} style={ theme === 'light' ? { color: 'var(--interactive)' } : { color: 'var(--interactive-dark)' }} />
                  </GVIconButton>
                </div>
                <div className="br-item">
                  <VwButton btnStyle={ theme === 'light' ? { color: 'var(--interactive)' } : { color: 'var(--interactive-dark)' }}  type="button" aria-label="Acessibilidade"/>
                </div>
              </div>
              <div className="br-list retract">
                <div className="header">
                  <div className="title">Funcionalidades do Sistema</div>
                </div>
                <div className="br-item">
                  <GVIconButton
                    id="fast-access-button"
                    className="circle small"
                    aria-label="Abrir Acesso Rápido"
                    type="button"
                    data-toggle="dropdown"
                    aria-controls={open ? 'fast-access-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    <FontAwesomeIcon icon={faGrip} style={ theme === 'light' ? { color: 'var(--interactive)' } : { color: 'var(--interactive-dark)' }} />
                  </GVIconButton>
                  <Menu
                    id="fast-access-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'fast-access-button',
                    }} >
                    <MenuItem onClick={toggleTheme}><FontAwesomeIcon icon={faCircleHalfStroke} style={ theme === 'light' ? { color: 'var(--black)' } : { color: 'var(--interactive-dark)' }} />&nbsp;&nbsp;&nbsp;Contraste</MenuItem>
                    <MenuItem onClick={toggleVwClass}  disabled={isVwActive} aria-label="Acessibilidade"><FontAwesomeIcon icon={faSignLanguage} style={ theme === 'light' ? { color: 'var(--black)' } : { color: 'var(--interactive-dark)' }}  type="button" />&nbsp;&nbsp;&nbsp;VLibras</MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-bottom">
          <div className="header-menu">
            <div className="header-info">
              <div className="header-title">Atendimento gov.br</div>
              <div className="header-subtitle">Ministério da Gestão e da Inovação em Serviços Públicos</div>
            </div>
          </div>
        </div>
      </div>
    </header>)
}

export default Header