import React, { useState, useCallback, useEffect, useRef } from 'react';
import Webcam from 'react-webcam';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import { Box, Button, Typography, Paper, Grid, IconButton } from '@mui/material';
import GVButton from '../button';
import GVMessage from '../message';
import CameraIcon from '@mui/icons-material/Camera';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import './styles.css'
import { Mexx2DOConsole } from '../../../utils/mexxTalkConsole';

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
  facingMode: FACING_MODE_USER,
};

const MultiStepCamera = ({ fieldName, onClose, onConfirm }) => {
  const { control, setValue, getValues } = useFormContext();
  const { fields, append, update } = useFieldArray({
    control,
    name: fieldName
  });

  const [deviceId, setDeviceId] = React.useState({});
  const [devices, setDevices] = React.useState([]);

  const [step, setStep] = useState(0);
  const [currentImage, setCurrentImage] = useState(null);
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
  const webcamRef = useRef(null);
  const videoRef = useRef(null);

  const [completed, setCompleted] = useState(false);
  const [captured, setCaptured] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [isDocumentDetected, setIsDocumentDetected] = useState(false);
  const [documentRecognized, setDocumentRecognized] = useState(false);
  const [stream, setStream] = useState(null);

  Mexx2DOConsole.log(navigator)

  const handleDevices = React.useCallback(
    mediaDevices => {
      setDevices(mediaDevices.filter(({ kind, label }) => kind === "videoinput" && (label.includes("1,"))))
      let mainDevice = mediaDevices.find(({ kind, label }) => kind === "videoinput" && label.includes("back"))
      if (mainDevice == null) {
        mainDevice = mediaDevices.find(({ kind }) => kind === "videoinput")
      }
      Mexx2DOConsole.log("=== handleDevices ===")
      Mexx2DOConsole.log(mainDevice)
      setDeviceId(mainDevice.deviceId)
    },
    [setDevices]
  );

  React.useEffect(
    () => {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    },
    [handleDevices]
  )

  /** TEST **/
  useEffect(() => {
    if (isDocumentDetected && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown(prev => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (countdown === 0 && isDocumentDetected) {
      capturePhoto();
    }
  }, [isDocumentDetected, countdown]);

  /*useEffect(() => {
    startBackCamera()
    startCamera();
    return () => {
      stopCamera();
    };
  }, [facingMode])*/

  const detectDocument = (imageSrc) => {
    const img = new Image();
    img.src = imageSrc;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      /*const src = cv.imread(canvas);
      const dst = new cv.Mat();
      cv.cvtColor(src, dst, cv.COLOR_RGBA2GRAY);
      cv.Canny(dst, dst, 50, 100, 3, false);
      
      // Adicione lógica adicional para detectar o documento e verificar o enquadramento
      // Se o documento estiver bem enquadrado, chame `setIsDocumentDetected(true)`

      src.delete();
      dst.delete();*/
    };
  };

  const capturePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCaptured(true);
    // Aqui você pode salvar a imagem ou fazer o que precisar com ela
  };

  const handleUserMedia = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    detectDocument(imageSrc);
  };
  /** TEST **/

  useEffect(() => {
    const defaultValues = [{ side: 'front', src: null }, { side: 'back', src: null }];
    if (!getValues(fieldName).length) {
      defaultValues.forEach(value => append(value));
    }
    setCompleted(false)
    setStep(0)
  }, [])

  useEffect(() => {
    const defaultValues = [{ side: 'front', src: null }, { side: 'back', src: null }];
    if (!getValues(fieldName).length) {
      defaultValues.forEach(value => append(value));
    }
  }, [append, getValues, fieldName]);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCurrentImage(imageSrc);
  }, [webcamRef]);

  const confirmImage = () => {
    Mexx2DOConsole.log("=== confirmImage ===")
    Mexx2DOConsole.log(fieldName)
    Mexx2DOConsole.log(step)
    Mexx2DOConsole.log(currentImage)
    update(step, { ...fields[step], src: currentImage });
    setValue(`${fieldName}[${step}].src`, currentImage);
    if (step === 0) {
      setStep(1);
    } else {
      onSubmit();
    }
    setCurrentImage(null);
  };

  const retakeImage = () => {
    setCurrentImage(null);
  };

  const toggleFacingMode = useCallback(() => {
    setFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    )
  }, [])
    /*{
    devices.forEach(item => {
      if (item.deviceId !== deviceId)
        setDeviceId(item.deviceId)
    })
  }*/

    const startCamera = async () => {
      Mexx2DOConsole.log("=== startCamera ===")
      try {
        const constraints = {
          video: {
            facingMode: facingMode // Define se será usada a câmera frontal ou traseira
          }
        };
        Mexx2DOConsole.log(constraints)
  
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        Mexx2DOConsole.log(stream)
        videoRef.current.srcObject = stream;
        Mexx2DOConsole.log(videoRef)
  
        const videoTrack = stream.getVideoTracks();
        Mexx2DOConsole.log(videoTrack)
  
        Mexx2DOConsole.log('Stream de vídeo ativo:', videoTrack);
      } catch (error) {
        Mexx2DOConsole.error('Erro ao acessar dispositivos de mídia: ', error);
      }
    };
  
    // Função para parar a câmera
    const stopCamera = () => {
      Mexx2DOConsole.log("=== stopCamera ===")
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach(track => track.stop());
      }
    };

    const requestCameraPermission = async _ => {
      try {
        // Solicita permissão para a câmera frontal (facingMode: "user")
        const frontCameraStream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'user' },
          audio: true
        });
        Mexx2DOConsole.log('Permissão concedida para a câmera frontal.');
        
        // Para o stream da câmera frontal após a permissão
        frontCameraStream.getTracks().forEach(track => track.stop());
    
        // Solicita permissão para a câmera traseira (facingMode: "environment")
        const backCameraStream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' },
          audio: true
        });
        Mexx2DOConsole.log('Permissão concedida para a câmera traseira.');
        
        // Para o stream da câmera traseira após a permissão
        backCameraStream.getTracks().forEach(track => track.stop());
    
      } catch (error) {
        console.error('Erro ao acessar as câmeras: ', error);
      }
    }

    const startBackCamera = async _ => {
      Mexx2DOConsole.log('=== startBackCamera ===');
      try {
        // Tenta acessar a câmera traseira diretamente
        const backCameraStream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' }
        });
        Mexx2DOConsole.log('Câmera traseira ativa.');
        
        // Aqui você pode vincular o stream de vídeo à tag <video> se necessário
        const videoElement = document.querySelector('video');
        videoElement.srcObject = backCameraStream;
      } catch (error) {
        console.error('Erro ao acessar a câmera traseira: ', error);
      }
    }

  /*const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [videoElement, setVideoElement] = useState(null);

  useEffect(() => {
    // Função para monitorar a visibilidade do vídeo
    const observeVideoVisibility = (video) => {
      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0];
          if (entry.isIntersecting) {
            setIsVideoVisible(true); // Vídeo visível
          } else {
            setIsVideoVisible(false); // Vídeo não visível
          }
        },
        { threshold: 0.5 } // 50% do vídeo precisa estar visível
      );

      if (video) {
        observer.observe(video); // Observar o vídeo
      }

      return () => {
        if (video) {
          observer.unobserve(video); // Parar de observar quando o componente desmontar
        }
      };
    };

    // Monitorar a existência do elemento <video>
    const interval = setInterval(() => {
      const video = document.querySelector('video');
      if (video) {
        setVideoElement(video); // Armazenar a referência do vídeo
        observeVideoVisibility(video); // Iniciar o observador quando o vídeo aparecer
        clearInterval(interval); // Parar de checar a existência após encontrar o vídeo
      }
    }, 500); // Verifica a cada 500ms

    return () => clearInterval(interval); // Limpar o intervalo ao desmontar
  }, []);

  useEffect(() => {
    if (isVideoVisible) {
      Mexx2DOConsole.log('O vídeo está visível na tela!');
      // Chame sua função aqui, quando o vídeo aparecer
      handleFaceLivenessCompletion()
    }
  }, [isVideoVisible]);

  const handleFaceLivenessCompletion = async (stream) => {
    // 1. Liberar a câmera frontal
    releaseCamera();
  
    // 2. Tentar ativar a câmera traseira
    await startBackCamera();
  }

  const stopAllStreams = (stream) => {
    stream.getTracks().forEach(track => track.stop()); // Para todos os streams ativos
  }
  
  const releaseCamera = () => {
    const videoElement = document.querySelector('video'); // Assumindo que a câmera frontal foi ligada
    if (videoElement.srcObject) {
      stopAllStreams(videoElement.srcObject); // Parando todos os tracks de vídeo e áudio
      videoElement.srcObject = null; // Liberando o elemento de vídeo
    }
  }*/

  const onSubmit = () => {
    setCompleted(true)
    onConfirm()
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={2}>
      <Controller
        name={fieldName}
        control={control}
        render={() => (
          <>
            {currentImage ? (
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Paper elevation={3} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <img src={currentImage} alt="Imagem com a pré-visualização da captura do documento" style={{ maxWidth: '100%', maxHeight: '400px' }} />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ paddingTop: 15 }} />
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 15 }}>
                  <GVButton
                    variant="text"
                    onClick={retakeImage}
                    sx={{ mr: 1 }}
                    label="Refazer leitura" />
                  <GVButton 
                    onClick={confirmImage}
                    variant="contained"
                    label="Confirmar" />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={0}>
                {step == 0 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <GVMessage info>Primeiro utilize a frente do documento</GVMessage>
                </Grid>}
                {step == 1 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <GVMessage info>Agora utilize o verso do documento</GVMessage>
                </Grid>}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Paper elevation={3} className='document-recognition-paper'>
                    <div className="overlay">
                      <div className="overlay-element top-left"></div>
                      <div className="overlay-element top-right"></div>
                      <div className="overlay-element bottom-left"></div>
                      <div className="overlay-element bottom-right"></div>
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={{
                          ...videoConstraints,
                          facingMode: facingMode
                        }}
                        style={{ width: '100%' }}
                      />
                      <IconButton className="document-recognition-togglecamera" aria-label="mudar-camera" onClick={toggleFacingMode}>
                        <CameraswitchIcon style={{ fontSize: '1em' }} />
                      </IconButton>
                      <IconButton className="document-recognition-capture" aria-label="capturar" onClick={capture}>
                        <CameraIcon style={{ fontSize: '1.5em' }} />
                      </IconButton>
                    </div>
                      {/*<IconButton className="document-recognition-togglecamera" aria-label="mudar-camera" onClick={toggleFacingMode}>
                        <CameraswitchIcon style={{ fontSize: '1em' }} />
                      </IconButton>
                      <IconButton className="document-recognition-capture" aria-label="capturar" onClick={capture}>
                        <CameraIcon style={{ fontSize: '1.5em' }} />
                      </IconButton>*/}
                    {/** TEST **/}
                    {isDocumentDetected && (
                      <div>
                        {captured ? (
                          <p>Foto capturada!</p>
                        ) : (
                          <p>Capturando foto em {countdown} segundos...</p>
                        )}
                      </div>
                    )}
                    {/** TEST **/}
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ paddingTop: 15 }} />
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 15 }}>
                  <GVButton
                    variant="outlined"
                    color="inherit"
                    onClick={onClose}
                    sx={{ mr: 1 }}
                    label="Cancelar" />
                  <GVButton 
                    onClick={onConfirm}
                    variant="contained"
                    disabled={!completed}
                    label="Confirmar" />
                </Grid>
              </Grid>
            )}
          </>
        )}
      />
    </Box>
  );
};

export default MultiStepCamera;